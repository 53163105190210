<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Setup Your Account</v-toolbar-title>
              </v-toolbar>
              <v-progress-linear
                :active="this.$store.getters.showLoader"
                indeterminate
              ></v-progress-linear>
              <v-form
                @submit.prevent="setupAccount"
                method="post"
                ref="setupAccountForm"
              >
                <v-card-text>
                  <p>
                    <strong>Welcome!</strong>
                  </p>
                  <p>
                    Before we proceed to Viewpoint itself, we would like to ask
                    that you complete the setup of your account by filling in
                    the form below.
                  </p>
                  <v-text-field
                    label="First Name"
                    name="firstName"
                    type="text"
                    v-model="setupForm.firstName"
                    :rules="[setupForm.rules.required]"
                    :error="setupForm.errors.hasOwnProperty('firstName')"
                    :error-messages="setupForm.errors['firstName']"
                  ></v-text-field>
                  <v-text-field
                    label="Last Name"
                    name="lastName"
                    type="text"
                    v-model="setupForm.lastName"
                    :rules="[setupForm.rules.required]"
                    :error="setupForm.errors.hasOwnProperty('lastName')"
                    :error-messages="setupForm.errors['lastName']"
                  ></v-text-field>
                  <v-alert type="info" outlined prominent class="mt-4 mb-4">
                    <strong>A strong password is required.</strong> Enter 8-100
                    characters. Do not include common words or names. Combine
                    uppercase letters, lowercase letters, numbers and symbols.
                  </v-alert>
                  <v-text-field
                    label="Password"
                    name="password"
                    v-model="setupForm.password"
                    counter="100"
                    :type="setupForm.show.new ? 'text' : 'password'"
                    :append-icon="
                      setupForm.show.new ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="setupForm.show.new = !setupForm.show.new"
                    :rules="[
                      setupForm.rules.required,
                      setupForm.rules.password,
                    ]"
                    :error="setupForm.errors.hasOwnProperty('password')"
                    :error-messages="setupForm.errors['password']"
                  ></v-text-field>
                  <v-text-field
                    label="Repeat Password"
                    name="passwordRepeat"
                    v-model="setupForm.passwordRepeat"
                    counter="100"
                    :type="setupForm.show.newConfirmed ? 'text' : 'password'"
                    :append-icon="
                      setupForm.show.newConfirmed ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    @click:append="
                      setupForm.show.newConfirmed = !setupForm.show.newConfirmed
                    "
                    :rules="[setupForm.rules.required, setupForm.rules.match]"
                    :error="
                      setupForm.errors.hasOwnProperty('password_confirmation')
                    "
                    :error-messages="setupForm.errors['password_confirmation']"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="logout">Logout</v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="setupForm.loading"
                    >Save Details</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      setupForm: {
        firstName: this.$store.getters.userFirstName,
        lastName: this.$store.getters.userLastName,
        password: null,
        passwordRepeat: null,
        errors: {},
        rules: {
          required: (value) => !!value || "Field is Required.",
          password: (value) => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,100})/;
            return (
              pattern.test(value) ||
              "Min. 8 characters with at least one capital letter, a number and a special character."
            );
          },
          match: (value) =>
            value === this.setupForm.password || "Passwords must match.",
        },
        show: {
          new: false,
          newConfirmed: false,
        },
        loading: false,
      },
    };
  },

  methods: {
    setupAccount: function() {
      if (!this.$refs.setupAccountForm.validate()) {
        return;
      }

      const firstName = this.setupForm.firstName;
      const lastName = this.setupForm.lastName;
      const password = this.setupForm.password;
      const password_confirmation = this.setupForm.passwordRepeat;
      this.setupForm.loading = true;

      this.$store
        .dispatch("setupAccount", {
          firstName,
          lastName,
          password,
          password_confirmation,
        })
        .then(() => {
          this.setupComplete();
          this.setupForm.loading = false;
        })
        .catch((err) => {
          this.setupForm.loading = false;
          this.setupForm.errors = err.response.data.errors;
        });
    },
    setupComplete: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/setup-complete");
      });
    },
    logout: function() {
      this.$store.commit("showHideLoader", true);
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
